<template>
  <div class="sheet_wrap">
    <div class="image">
      <div>
        <div class="label"
             style="margin-left:0px">答题卡正面</div>
        <div v-if="form.excel_image_one"
             class="pub"
             :style="{
            width:fixed_1_width + 'px',
            height:fixed_1_height + 'px',
            background:`url(${form.excel_image_one+'?random='+Math.random()}}
)`,
            'background-size':`${fixed_1_width}px ${fixed_1_height}px`
          }">
        </div>
        <div v-else
             class="add_img"
             :style="{
            width:fixed_1_width + 'px',
            height:fixed_1_height + 'px'}">
          <div class="wrap">
            <div>
              <img src="@/assets/addimg.png"
                   alt="">
            </div>
            请上传答题卡正面
          </div>
        </div>
      </div>
      <div>
        <div class="label">预览 <div v-if="form.excel_image_one&&signAbled"
               @click="showSign(1)">标注</div>
        </div>
        <div class="priview"
             :style="{
              width:fixed_1_width + 'px',
            height:fixed_1_height + 'px',
            background:`url(${form.excel_image_one+'?random='+Math.random()})`,
            'background-size':`${fixed_1_width}px ${fixed_1_height}px`
        }">
          <!-- v-for="(item,index) in JSON.parse(form.mark_image_one_json)" -->
          <div class="sign"
               v-for="(item,index) in jsonUI"
               :key="index"
               v-show="item.points.w"
               :style="{
          top:item.points.y / ratioH+'px',
        left:item.points.x / ratioW+'px',
        width:item.points.w / ratioW+'px',
        height:item.points.h / ratioH+'px',
        background:item.color
        }">
            {{item.label}}
          </div>
        </div>
      </div>
    </div>

    <div class="fixed_box"
         v-if="show">
      <Sign ref="signRef1"
            @setData="setData"
            @cancelSign="cancelSign"
            :size="size" />
    </div>
    <img class="fixed_1"
         :src="form.excel_image_one+'?random='+Math.random()"
         alt="">

  </div>
</template>

<script>
import Sign from '../Sign.vue'

export default {
  components: {
    Sign
  },
  data () {
    return {
      ratioH: 1,
      ratioW: 1,
      // 预览图的属性
      fixed_1_width: 560,
      fixed_1_height: 417,
      show: false,
      jsonUI: []
    }


  },
  props: ['form', 'signAbled', 'size'],
  watch: {
    // 显示的标注数据，
    // 区域数据相同的合并为一个，标签内容叠加
    'form.mark_image_one_json': {
      handler () {
        this.jsonUI = []
        let markJson = JSON.parse(this.form['mark_image_one_json'])
        let points = []
        markJson.map(item => {
          points.push(JSON.stringify(item.points));
        })
        let pointsSingle = [...new Set(points)]
        pointsSingle.forEach(item => {
          let temp = markJson.filter(ele => JSON.stringify(ele.points) == item)
          if (temp.length == 1) {
            this.jsonUI.push(...temp)
          } else {
            let t = temp[0]
            for (let i = 1; i < temp.length; i++) {
              t.label += ','
              t.label += temp[i].label
            }
            this.jsonUI.push(t)
          }
        })
      },
    }
  },


  methods: {
    cancelSign () {
      this.show = false
    },
    getFixedImage () {
      this.$forceUpdate()
      // setTimeout(() => {
      var img1 = document.getElementsByClassName('fixed_1')[0]
      // this.ratioW = img1.clientWidth / 560
      // this.ratioH = img1.clientHeight / 417
      let that = this
      img1.onload = () => {
        if (img1.clientWidth < img1.clientHeight) {
          this.fixed_1_width = 417
          this.fixed_1_height = 560

        } else {
          this.fixed_1_width = 560
          this.fixed_1_height = 417
        }
        this.ratioW = img1.clientWidth / this.fixed_1_width
        this.ratioH = img1.clientHeight / this.fixed_1_height
        console.log(11)
      }

      // }, 1000);

    },
    showSign (num) {
      this.show = true
      this.$nextTick(() => {
        let url = ''
        if (num == 1) {
          url = this.form.excel_image_two + '?random=' + Math.random()
        } else {
          url = this.form.mark_image_two + '?random=' + Math.random()
        }
        let urlObj = {
          1: 'excel_image_one',
          2: 'excel_image_two'
        }
        let jsonObj = {
          1: 'mark_image_one_json',
          2: 'mark_image_two_json'
        }
        let json = JSON.parse(this.form[jsonObj[num]]).map(item => {
          item.points.y *= 0.3
          item.points.x *= 0.3
          item.points.w *= 0.3
          item.points.h *= 0.3

          return item
        })
        this.$refs.signRef1.initData(json, this.form[urlObj[num]] + '?random=' + Math.random(), num)
        // this.$refs.signRef.size = this.size

      })
    },
    setData (num, url, json) {
      let jsonObj = {
        1: 'mark_image_one_json',
        2: 'mark_image_two_json'
      }
      this.form[jsonObj[num]] = json
      this.show = false
    },

  }

}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 10px;
}
.sign {
  color: #eee;
  text-shadow: 1px 1px 1px black;
}
.image {
  display: flex;
  .label {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    height: 38px;
    color: #333333;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    margin-left: 50px;
    div {
      width: 90px;
      height: 32px;
      background: #ecf5ff;
      border-radius: 2px;
      border: 1px solid #3f9eff;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      font-weight: bold;
      color: #3f9eff;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  div {
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .btn {
    margin-left: 20px;
  }
}
.fixed_box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2000;
  background: white;
  overflow: auto;
}
.pub {
  border-radius: 10px;
  border: 2px solid #d9d9d9;
}
.priview {
  position: relative;
  width: 560px;
  height: 417px;
  margin-left: 50px;
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  div {
    position: absolute;
    background: red;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.fixed_1 {
  position: fixed;
  right: 100000px;
}
.fixed_2 {
  position: fixed;
  right: 100000px;
}
::v-deep .el-message--warning {
  z-index: 2001 !important;
}
.add_img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  // cursor: pointer;
  img {
    width: 80px;
    height: 72px;
    margin-bottom: 20px;
  }
}
.sheet_wrap {
  overflow: auto;
}
</style>