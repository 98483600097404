export const points = [{ 'name': '定位点1', 'label': 'lp1', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点2', 'label': 'lp2', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点3', 'label': 'lp3', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点4', 'label': 'lp4', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点5', 'label': 'lp5', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点6', 'label': 'lp6', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点7', 'label': 'lp7', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点8', 'label': 'lp8', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '准考证填涂区', 'label': 'rn', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'rn' },
{ 'name': '准考证手写区', 'label': 'rn_hand', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'rn_hand' },
{ 'name': '用户条码区', 'label': 'uid', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'uid' },
]

export const points1 = [{ 'name': '定位点1', 'label': 'lp1', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点2', 'label': 'lp2', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点3', 'label': 'lp3', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点4', 'label': 'lp4', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点5', 'label': 'lp5', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点6', 'label': 'lp6', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点7', 'label': 'lp7', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp' },
{ 'name': '定位点8', 'label': 'lp8', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },

]

export const pointsA4 = [{ 'name': '定位点1', 'label': 'lp1', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点2', 'label': 'lp4', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点3', 'label': 'lp5', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点4', 'label': 'lp8', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '准考证填涂区', 'label': 'rn', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'rn' },
{ 'name': '准考证手写区', 'label': 'rn_hand', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'rn_hand' },
{ 'name': '用户条码区', 'label': 'uid', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'uid' },
]

export const points1A4 = [{ 'name': '定位点1', 'label': 'lp1', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点2', 'label': 'lp4', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点3', 'label': 'lp5', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
{ 'name': '定位点4', 'label': 'lp8', 'points': { 'x': 0, 'y': 0, 'w': 0, 'h': 0 }, 'color': '', 'type': 'lp', 'isRequire': true },
]