<template>
  <div>

    <el-form label-width="120px">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="上传Word文件"
                        v-if="fileType!=2">
            <el-button type="primary"
                       style="padding:10px 30px"
                       @click="importWord"
                       :disabled="!!fileList.excel"
                       v-if="!fileList.word">{{isEdit?"重新上传":'选择'}}</el-button>

            <el-tag @close="clear('word')"
                    closable
                    type="priamry"
                    v-else>{{fileList.word?fileList.word.name:''}}</el-tag>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="上传PDF文件">
            <el-button type="primary"
                       style="padding:10px 30px"
                       @click="importPDF"
                       v-if="!fileList.pdf">{{isEdit?"重新上传":'选择'}}</el-button>

            <el-tag @close="clear('pdf')"
                    closable
                    type="priamry"
                    v-else>{{fileList.pdf?fileList.pdf.name:''}}</el-tag>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="10"
                style="text-align:center">
          <el-col :span="24">
            <el-button type="primary"
                       @click="submit">提交</el-button>
          </el-col>
        </el-row> -->

    </el-form>
  </div>
</template>

<script>
import getFile from '@/utils/file.js'

export default {
  data () {

    return {
      fileList: {
        word: undefined,
        // excel: undefined,
        pdf: undefined
      },
      fileType: 0 //0:word,pdf,excel  1:word,pdf  2:excel,pdf
    }
  },
  props: ['isEdit'],
  methods: {

    submit () {
      if (!this.fileList.word && !this.fileList.excel) {
        this.$message.warning("请选择excel文件或word文件")
        return
      }
      if (!this.fileList.pdf) {
        this.$message.warning("请选择pdf文件")
        return
      }
      this.$emit('importSubmit', this.fileList)
    },
    clear (type) {
      this.fileList[type] = undefined
      this.$emit('resetFile')
    },
    async importWord (str) {
      let limit = 200
      let fileType = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      const res = await getFile({ limit, fileType })
      this.$emit('resetFile')
      console.log(res, "res")
      this.fileList.word = res
      if (this.fileList.word && this.fileList.pdf) {
        this.$emit('importSubmit', this.fileList)
      }
      console.log(this.fileList)
    },
    async importExcel (str) {
      let limit = 200
      let fileType = "excel,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
      const res = await getFile({ limit, fileType })
      this.$emit('resetFile')
      this.fileList.excel = res
      console.log(this.fileList)
      // let formData = new FormData()
    },
    async importPDF (str) {

      let limit = 200
      let fileType = ".pdf"
      const res = await getFile({ limit, fileType })
      this.$emit('resetFile')
      console.log(res, "res")
      this.fileList.pdf = res
      if (this.fileList.word && this.fileList.pdf) {
        this.$emit('importSubmit', this.fileList)
      }
      console.log(this.fileList)
      // let formData = new FormData()
    },

    reset () {
      this.fileList = {
        word: undefined,
        excel: undefined,
        pdf: undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hide_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>