var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sheet_wrap"},[_c('div',{staticClass:"image"},[_c('div',[_c('div',{staticClass:"label",staticStyle:{"margin-left":"0px"}},[_vm._v("答题卡反面")]),(_vm.form.excel_image_two)?_c('div',{staticClass:"pub",style:({
          width:_vm.fixed_1_width + 'px',
          height:_vm.fixed_1_height + 'px',
          background:("url(" + (_vm.form.excel_image_two) + "?random=" + (Math.random()) + ")"),
          'background-size':(_vm.fixed_1_width + "px " + _vm.fixed_1_height + "px")
        })}):_c('div',{staticClass:"add_img",style:({
          width:_vm.fixed_1_width + 'px',
          height:_vm.fixed_1_height + 'px'})},[_vm._m(0)])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("预览 "),(_vm.form.excel_image_two&&_vm.signAbled)?_c('div',{on:{"click":function($event){return _vm.showSign(2)}}},[_vm._v("标注")]):_vm._e()]),_c('div',{staticClass:"priview",style:({ 
          width:_vm.fixed_1_width + 'px',
          height:_vm.fixed_1_height + 'px',
          background:("url(" + (_vm.form.excel_image_two +'?random='+Math.random()) + ")"),
          'background-size':(_vm.fixed_1_width + "px " + _vm.fixed_1_height + "px")
        })},_vm._l((_vm.jsonUI),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.points.w),expression:"item.points.w"}],key:index,staticClass:"sign",style:({
              top:item.points.y / _vm.ratioH+'px',
      left:item.points.x / _vm.ratioW+'px',
      width:item.points.w / _vm.ratioW+'px',
      height:item.points.h / _vm.ratioH+'px',
            background:item.color
      })},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)])]),(_vm.show)?_c('div',{staticClass:"fixed_box"},[_c('Sign',{ref:"signRef2",attrs:{"size":_vm.size},on:{"setData":_vm.setData,"cancelSign":_vm.cancelSign}})],1):_vm._e(),_c('img',{staticClass:"fixed_2",attrs:{"src":_vm.form.excel_image_two+'?random='+Math.random(),"alt":""}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/addimg.png"),"alt":""}})]),_vm._v(" 请上传答题卡反面 ")])}]

export { render, staticRenderFns }